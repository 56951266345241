import './Home.css';
import React, { useState, useContext, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { Autocomplete, Collapse, Slider, Button, Typography, Box } from '@mui/material';
import { ClipLoader } from 'react-spinners';
import { AuthContext } from '../components/AuthContext';
import { FirestoreContext } from '../components/FirestoreContext';
import AuthModal from '../components/AuthModal'
import { collection, doc, serverTimestamp, setDoc, getDocs, query, where } from 'firebase/firestore';
import Tooltip from '@mui/material/Tooltip';
import CustomButton from '../components/CustomButton';
import './Dashboard.css'


const Home = () => {
  const options = ['Dow 30', 'S&P 500', 'Nasdaq 100']
  const dateOptions = ['2024-12-20', '2025-01-17', '2025-02-21', '2025-04-17']
  const optionTypes = ["Calls", "Puts"]
  const [selectedOption, setSelectedOption] = useState('');
  const [portfolioSize, setPortfolioSize] = useState('');
  const [selectedExpirationDate, setSelectedExpirationDate] = useState('');
  const [advancedDrawerOpen, setAdvancedDrawerOpen] = useState(false);
  const [optionDelta, setOptionDelta] = useState(0.4);
  const [optionType, setOptionType] = useState("Calls");
  const [selectedIndexEtf, setSelectedIndexEtf] = useState('');
  const [positions, setPositions] = useState(null);
  const [totalPremium, setTotalPremium] = useState('');
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const { currentUser, isNewAccountRegistered, setHasPositionsSaved, hasPositionsSaved, setSavePositionsLoading } = useContext(AuthContext);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const db = useContext(FirestoreContext);

  const handleInputChange = (e) => {
    let value = e.target.value.replace(/,/g, "");
    if (!isNaN(value) && value >= 0) {
      setPortfolioSize(value);
    }
  }

  const error = portfolioSize > 10000000;
  const formattedPortfolioSize = parseInt(portfolioSize).toLocaleString();

  const fetchIndexData = () => {
    setLoading(true);
    const apiUrl = 'https://us-central1-income-with-options.cloudfunctions.net/api/fetchIndexData'
    //const apiUrl = 'http://localhost:5000/fetchIndexData'

    const params = new URLSearchParams();
    params.append('selectedOption', selectedOption);
    params.append('portfolioSize', portfolioSize);
    params.append('selectedExpirationDate', selectedExpirationDate);
    params.append('optionType', optionType);
    params.append('optionDelta', optionDelta);

    //event track
    window.gtag('event', 'portfolio_calculated', {
      'index': selectedOption,
      'portfolio_size': portfolioSize,
      'expiration_date': selectedExpirationDate
    });

    fetch(apiUrl + '?' + params)
      .then(response => response.json())
      .then(data => {
        setPositions(data.myPositions);
        setSelectedIndexEtf(data.indexEtfTicker);
        setTotalPremium(data.premiumValue);
        setLoading(false);
        setDataLoaded(true);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setDataLoaded(true);
      });;
  }

  const convertArrayToStockObject = (arr) => {
    return {
      stockSymbol: arr[0],
      quantity: arr[1],
      purchasePrice: arr[2],
      totalPurchasePrice: arr[3]
    };
  };

  const convertArrayToOptionObject = (arr) => {
    return {
      stockSymbol: arr[0],
      optionSymbol: arr[8],
      quantity: (arr[1] / -100),
      purchasePrice: arr[6] * -1,
      totalPurchasePrice: arr[7] * -1
    };
  };

  const calculateCashBalance = (positions, startingValue, optionCredit) => {
    const positionsExceptLast = positions.slice(0, -1);
    const totalPositionsCost = positionsExceptLast.reduce((acc, position) => {
      return acc + (position[1] * position[2]);
    }, 0);

    return startingValue - totalPositionsCost + optionCredit;
  };

  const savePositions = async () => {
    setSavePositionsLoading(true);
    if (!currentUser) {
      console.log("Faied to save Positions - no user logged in")
    } else {
      const userId = currentUser.uid;
      try {
        const cashBalance = calculateCashBalance(positions, portfolioSize, totalPremium);

        const portfolioRef = doc(collection(db, 'portfolios'));
        await setDoc(portfolioRef, {
          createTime: serverTimestamp(),
          userId,
          startingValue: portfolioSize,
          currentValue: portfolioSize,
          benchmark: selectedIndexEtf,
          firstExpiration: new Date(selectedExpirationDate),
          cashBalance: cashBalance,
          optionType: optionType,
          optionDelta: optionDelta
        });

        const stockPositionsRef = collection(portfolioRef, 'stockPositions');
        const optionPositionsRef = collection(portfolioRef, 'optionPositions');
        const activitiesRef = collection(portfolioRef, 'activities');  // reference to activities collection

        for (const positionArray of positions.slice(0, -1)) {
          const stockPosition = convertArrayToStockObject(positionArray);
          const optionPosition = convertArrayToOptionObject(positionArray);

          const stockPositionDocRef = doc(stockPositionsRef);
          const optionPositionDocRef = doc(optionPositionsRef);

          await setDoc(stockPositionDocRef, stockPosition);
          await setDoc(optionPositionDocRef, optionPosition);

          // Create activity for stock position
          const stockActivity = {
            type: 'purchase',
            stockSymbol: stockPosition.stockSymbol,
            quantity: stockPosition.quantity,
            price: stockPosition.purchasePrice,
            created_at: serverTimestamp(),
            securityType: 'stock'
          };
          await setDoc(doc(activitiesRef), stockActivity);

          // Create activity for option position
          const optionActivity = {
            type: 'sale',
            optionSymbol: optionPosition.optionSymbol,
            stockSymbol: optionPosition.stockSymbol,
            quantity: optionPosition.quantity,
            price: optionPosition.purchasePrice,
            created_at: serverTimestamp(),
            securityType: 'option'
          };
          await setDoc(doc(activitiesRef), optionActivity);
        }

        console.log('Portfolio and positions saved successfully');
        setHasPositionsSaved(true);
        setSavePositionsLoading(false);
      } catch (error) {
        console.error('Error saving portfolio: ', error);
      }
    }
  };

  const fetchIndexDataAndShowModal = () => {
    fetchIndexData();
    setShowAuthModal(true);
    console.log(showAuthModal);
  };

  useEffect(() => {
    if (currentUser && isNewAccountRegistered) {
      savePositions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isNewAccountRegistered]);

  useEffect(() => {
    const checkPortfolioExists = async () => {
      if (currentUser && !isNewAccountRegistered) {
        const portfoliosRef = collection(db, 'portfolios');
        const q = query(portfoliosRef, where('userId', '==', currentUser.uid));

        try {
          const querySnapshot = await getDocs(q);
          setHasPositionsSaved(!querySnapshot.empty);
        } catch (error) {
          console.error('Error fetching portfolio:', error);
        }
      }
    };
    checkPortfolioExists();
    console.log("Aris");
    console.log(hasPositionsSaved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, isNewAccountRegistered]);

  return (
    <div className="App">
      <header className="App-header">
        <h2 className="h2-override">Stock Option Index Calculator</h2>
        <Autocomplete
          options={options}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select an index" variant="outlined" />
          )}
          value={selectedOption}
          onChange={(event, newValue) => {
            setSelectedOption(newValue);
          }}
        />
        <br></br>
        <TextField
          style={{ width: 300 }}
          label="Portfolio size ($)"
          variant="outlined"
          value={portfolioSize ? formattedPortfolioSize : ''}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">$</InputAdornment>
            ),
          }}
          error={error}
          helperText={error ? "Value cannot exceed $10,000,000" : ""}
        />
        <br />
        <Autocomplete
          options={dateOptions}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select an expiration date" variant="outlined" />
          )}
          value={selectedExpirationDate}
          onChange={(event, newValue) => {
            setSelectedExpirationDate(newValue);
          }}
        />
        <br></br>
        <Button
          variant="text"
          onClick={() => setAdvancedDrawerOpen(!advancedDrawerOpen)}
          endIcon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: advancedDrawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s'
              }}
            >
              <path d="M3 6L8 11L13 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          }
          sx={{ textTransform: 'none', color: 'black', fontSize: '.9rem' }}
        >
          Advanced: Delta & Option Type
        </Button>
        <Collapse in={advancedDrawerOpen}>
          <div>
            <Box sx={{ width: 300 }}>
              <Typography gutterBottom align="left" color="textSecondary" sx={{ marginTop: 2, marginBottom: -2 }}>
                Option Delta
            </Typography>
              <Slider
                defaultValue={optionDelta}
                step={0.05}
                marks
                min={0.10}
                max={0.60}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => value.toFixed(2)}
                sx={{ width: '90%', marginBottom: -2 }}
                onChangeCommitted={(event, newValue) => setOptionDelta(newValue)}
              />
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="caption" color="textSecondary">0.10</Typography>
                <Typography variant="caption" color="textSecondary">0.60</Typography>
              </Box>
            </Box>
            <br></br>
            <Autocomplete
              options={optionTypes}
              style={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select an option type" variant="outlined" />
              )}
              value={optionType}
              onChange={(event, newValue) => {
                if (optionTypes.includes(newValue)) {
                  setOptionType(newValue);
                } else {
                  console.error('Invalid option selected:', newValue);
                }
              }}
            />
          </div>
        </Collapse>
        <br></br>
        <div className="buttons-container">
          <CustomButton
            style={{ backgroundColor: portfolioSize > 10000000 ? 'gray' : 'blue', color: 'white' }}
            onClick={fetchIndexData}
            disabled={portfolioSize > 10000000}
          >
            See Positions
           </CustomButton>
          <CustomButton
            style={{ backgroundColor: portfolioSize > 10000000 ? 'gray' : 'blue', color: 'white' }}
            onClick={fetchIndexDataAndShowModal}
            disabled={portfolioSize > 10000000}
          >
            Track real-time performance
            </CustomButton>
        </div>
        <br></br>
        {loading && <ClipLoader size={40} color={"#123abc"} />}
        {positions && (
          <div>
            <table className="table-override">
              <thead>
                <tr>
                  <th style={{ padding: '10px' }}>Stock symbol</th>
                  <th style={{ padding: '10px' }}>Share count</th>
                  <th style={{ padding: '10px' }}>Share price</th>
                  <th style={{ padding: '10px' }}>Position size</th>
                  <th style={{ padding: '10px' }}>Strike price</th>
                  <th style={{ padding: '10px' }}>Delta</th>
                  <th style={{ padding: '10px' }}>Option price</th>
                  <th style={{ padding: '10px' }}>Total premium</th>
                </tr>
              </thead>
              <tbody>
                {positions ? (
                  positions.slice(0, -1).map((row, index) => (
                    <tr key={index}>
                      <td>{row[0]}</td>
                      <td>{row[1].toLocaleString("en-US")}</td>
                      <td>${parseFloat(row[2]).toFixed(2)}</td>
                      <td>${row[3].toLocaleString("en-US")}</td>
                      <td>${row[4]}</td>
                      <td>{row[5]}</td>
                      <td>${row[6]}</td>
                      <td>${parseFloat(row[7]).toLocaleString("en-US")}</td>
                    </tr>
                  ))
                ) : (
                    <tr>
                    </tr>
                  )}
              </tbody>
            </table>
            <h4>Portfolio premiums: ${parseFloat(totalPremium).toLocaleString("en-US")}</h4>
          </div>
        )}
        <div>
          {dataLoaded && (
            <Tooltip title={optionType === 'Puts' ? "Puts coming soon. Only available for calls." : ""}>
              <span>
                <CustomButton
                  style={{ backgroundColor: optionType === 'Puts' ? 'gray' : 'blue', color: 'white' }}
                  onClick={fetchIndexDataAndShowModal}
                  disabled={optionType === 'Puts'}
                >
                  Track real-time performance
            </CustomButton>
              </span>
            </Tooltip>
          )}
        </div>
        <br></br>
        {positions && (
          <div>
            <p>Benchmark ETF: {selectedIndexEtf}</p>
            <table className="table-override">
              <thead>
                <tr>
                  <th style={{ padding: '10px' }}>Stock symbol</th>
                  <th style={{ padding: '10px' }}>Share count</th>
                  <th style={{ padding: '10px' }}>Share price</th>
                  <th style={{ padding: '10px' }}>Position size</th>
                  <th style={{ padding: '10px' }}>Strike price</th>
                  <th style={{ padding: '10px' }}>Delta</th>
                  <th style={{ padding: '10px' }}>Option price</th>
                  <th style={{ padding: '10px' }}>Total premium</th>
                </tr>
              </thead>
              <tbody>
                {positions ? (
                  positions.slice(-1).map((row, index) => (
                    <tr key={index}>
                      <td>{row[0]}</td>
                      <td>{row[1].toLocaleString("en-US")}</td>
                      <td>${parseFloat(row[2]).toFixed(2)}</td>
                      <td>${row[3].toLocaleString("en-US")}</td>
                      <td>${row[4]}</td>
                      <td>{row[5]}</td>
                      <td>${row[6]}</td>
                      <td>${parseFloat(row[7]).toLocaleString("en-US")}</td>
                    </tr>
                  ))
                ) : (
                    <tr>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
        )}
        <div>
          {showAuthModal && (
            <AuthModal onClose={() => setShowAuthModal(false)} />
          )}
        </div>
      </header>
    </div>
  );
}

export default Home;
